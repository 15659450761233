<template>
  <SectionTitle title1="Services" title2="What we do?" :isLine="true" />
  <CardItem :items="cardItems" />
  <ForClient
    v-for="(banner, index) in bannnerData"
    :key="index"
    :image="banner.image"
    :title="banner.title"
    :bgColor="banner.bgColor"
    :btn="banner.btn"
    :isStart="banner.isStart"
  />
</template>

<script>
import CardItem from '@/components/CardItem.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import ForClient from '@/components/ForClient.vue';

export default {
  name: 'ServicesContainer',
  components: {
    CardItem,
    SectionTitle,
    ForClient,
  },
  data() {
    return {
      bannnerData: [
        {
          image: require('@/assets/banner-img-1.png'),
          title:
            'Scale your Business with a Beautiful & Wonderful Responsive Website',
          bgColor: 'background-color: #0098F0',
          btn: 'View Portfolio',
          isStart: false,
        },
        {
          image: require('@/assets/banner-img-2.png'),
          title: 'Get your amazing Website Delivered on Time',
          bgColor: 'background-color: #17B962',
          btn: 'Contact Us',
          isStart: true,
        },
      ],
      cardItems: [
        {
          img: require('@/assets/card-img-1.png'),
          title: 'Web Application Development',
        },
        {
          img: require('@/assets/card-img-2.png'),
          title: 'Mobile App Development',
        },
        {
          img: require('@/assets/card-img-3.png'),
          title: 'UI UX Design',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
