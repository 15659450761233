<template>
  <v-lazy :options="{ threshold: 0.5 }" min-height="450">
    <v-card class="mx-auto card-item" height="450">
      <v-img :src="image" height="100%" width="100%" class="card-image" cover>
        <template #placeholder>
          <div class="skeleton" />
        </template>
      </v-img>

      <div
        class="card-info"
        :class="{
          'card-info-left': isStart === true,
          'card-info-right': isStart === false,
        }"
      >
        <span class="card-info-span">For Client</span>
        <p>{{ title }}</p>
        <v-btn class="card-btn" :style="bgColor">{{ btn }}</v-btn>
      </div>
    </v-card>
  </v-lazy>
</template>

<script>
export default {
  name: 'ForClient',
  props: ['image', 'title', 'bgColor', 'btn', 'isStart'],
};
</script>

<style scoped>
.card-item {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  margin-top: 30px;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
}

.card-info {
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  text-align: left;
  width: 30%;
}

.card-info span {
  font-weight: 400;
  font-size: 30px;
}

.card-info p {
  font-weight: 700;
  font-size: 30px;
  margin-top: 20px;
  line-height: 160%;
}

.card-info-right {
  position: absolute;
  bottom: 20%;
  right: 20%;
  transform: translateX(50%);
}
.card-info-left {
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: translateX(-50%);
}

.card-btn {
  border-radius: 0 !important;
  margin-top: 30px;
  color: white;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media (max-width: 1279px) {
  .card-info {
    width: 40%;
  }
  .card-info-right {
    right: 25%;
  }
  .card-info-left {
    left: 25%;
  }
}

@media (max-width: 960px) {
  .card-info {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .card-info {
    padding: 10px;
    text-align: center;
    width: 100%;
  }

  .card-info span {
    font-size: 24px;
  }

  .card-info p {
    font-size: 24px;
  }

  .card-btn {
    height: 50px;
  }

  .card-info-right {
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
  .card-info-left {
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-info-right span {
    position: absolute;
    top: -35% !important;
    left: 20% !important;
    transform: translateX(-50%) !important;
  }
  .card-info-left span {
    position: absolute;
    top: -60% !important;
    left: 20% !important;
    transform: translateX(-50%) !important;
  }
}
</style>
