<template>
  <SectionTitle title1="Our Location" title2="Where are we.?" :isLine="false" />
  <v-lazy :options="{ threshold: 0.5 }" min-height="600">
    <v-card class="card-item" elevation="0" height="600">
      <v-img class="card-image" :src="require('@/assets/location-img-1.png')">
        <template #placeholder>
          <div class="skeleton" />
        </template>
      </v-img>
      <v-card-title class="card-title">Singapore</v-card-title>
    </v-card>
  </v-lazy>
  <LocationItem />
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue';
import LocationItem from './LocationItem.vue';
export default {
  name: 'LocationContainer',
  components: {
    SectionTitle,
    LocationItem,
  },
};
</script>

<style scoped>
.card-item {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  cursor: pointer;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.card-title {
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translateX(-50%);
  color: white;
  font-size: 60px;
  height: 60px;
  font-weight: bold;
  text-align: left;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media (max-width: 1279px) {
  .card-image {
    object-fit: none;
    object-position: -300px 0;
  }
}

@media (max-width: 640px) {
  .card-image {
    object-fit: none;
    object-position: -650px 0 !important;
    width: 100%;
    height: 100%;
    scale: 3.6;
  }
  .card-title {
    font-size: 36px;
    left: 30%;
  }
}
</style>
