<template>
  <v-app>
    <div>
      <header-vue />
      <RouterView v-slot="{ Component }">
        <Transition name="page-opacity" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </div>
  </v-app>
</template>

<script>
import { RouterView } from 'vue-router';
import HeaderVue from './components/HeaderVue.vue';
export default {
  name: 'App',
  components: { RouterView, HeaderVue },
  data: () => ({
    //
  }),
};
</script>

<style>
.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 500ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}
</style>
