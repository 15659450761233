<template>
  <v-lazy :options="{ threshold: 0.5 }" min-height="400">
    <v-card class="location-container d-flex">
      <v-img :src="require('@/assets/location-img-2.png')">
        <template #placeholder>
          <div class="skeleton" />
        </template>
      </v-img>
      <div class="card-item-cont">
        <p class="card-item-section">For Client</p>
        <h5 class="card-item-title">Are you looking to develop a App . ?</h5>
        <div
          class="card-item d-flex"
          v-for="(item, index) in listItems"
          :key="index"
        >
          <span class="mdi mdi-star-circle"></span>
          <div class="card-item-text">
            <h5>{{ item.title }}</h5>
            <p>{{ item.tech }}</p>
          </div>
        </div>
        <v-btn class="card-btn"> Send Inquiry </v-btn>
      </div>
    </v-card>
  </v-lazy>
</template>

<script>
export default {
  name: 'LocationItem',
  data() {
    return {
      screenWidth: window.innerWidth,
      listItems: [
        {
          title: 'Web App',
          tech: 'Vue js, Vuetify, NuxtJs',
        },
        {
          title: 'Mobile App',
          tech: 'React Native, Flutter',
        },
        {
          title: 'UI UX',
          tech: 'Figma',
        },
      ],
    };
  },
  computed: {
    isSmall() {
      return this.screenWidth < 640;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.location-container {
  gap: 30px;
  margin-top: 40px;
  box-shadow: none;
}
.location-container img {
  max-height: 600px;
}
.card-item-cont {
  box-shadow: none;
  color: #000;
}

.card-item-section {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  font-size: 34px;
}

.card-item {
  gap: 20px;
  margin: 35px 0;
}

.card-item span {
  font-size: 60px;
  margin-top: -20px;
}

.card-item-text h5 {
  font-weight: 600;
  font-size: 30px;
}
.card-item-text p {
  font-weight: 400;
  font-size: 18px;
  margin-top: 16px;
}

.card-btn {
  background: #108a00;
  border-radius: 50px;
  color: white;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  width: 160px;
  height: 58px;
  text-transform: none;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media (max-width: 1279px) {
  .location-container {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .card-item-section {
    font-size: 24px;
  }

  .card-item-title {
    font-size: 26px;
  }

  .card-item span {
    font-size: 35px;
    margin-top: -5px;
  }

  .card-item-text h5 {
    font-size: 26px;
  }
}
</style>
