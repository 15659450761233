<template>
  <div class="section-title">
    <p style="color: #444ac1">{{ title1 }}</p>
    <p style="color: #000000">{{ title2 }}</p>
    <div v-if="isLine" class="line-section" />
  </div>
</template>

<script>
export default {
  props: ['title1', 'title2', 'isLine'],
};
</script>

<style scoped>
.section-title {
  font-weight: 700;
  font-size: 65px;
  line-height: 97px;
  text-align: center;
  margin: 60px 0;
}

.line-section {
  margin: 20px auto;
  height: 3px;
  background: #647d84;
  width: 25%;
}

@media (max-width: 640px) {
  .section-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
  }
  .line-section {
    width: 80%;
  }
}
</style>
