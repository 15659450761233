<template>
  <div>
    <CarouselItem />
    <v-container>
      <ServicesContainer />
      <LocationContainer />
    </v-container>
    <FooterItem />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CarouselItem from '@/components/CarouselItem.vue';
import FooterItem from '@/components/FooterItem.vue';
import ServicesContainer from '@/components/Services/ServicesContainer.vue';
import LocationContainer from '@/components/Location/LocationContainer.vue';

// Components

export default defineComponent({
  name: 'HomeView',
  components: {
    CarouselItem,
    FooterItem,
    ServicesContainer,
    LocationContainer,
  },
});
</script>
