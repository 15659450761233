<template>
  <v-app-bar fixed :elevation="isSmall ? 0 : 2">
    <div class="logo-img-container">
      <v-img
        class="logo-img ml-6"
        :src="require('@/assets/logo-navbar.png')"
        height="90"
        transition="fade-transition"
      >
        <template #placeholder>
          <div class="skeleton" />
        </template>
      </v-img>
    </div>
    <div v-if="isSmall === false" class="desktop__app">
      <v-menu>
        <template #activator="{ props }">
          <v-btn
            class="menu-item"
            :class="{ 'ml-6': !isSmall }"
            v-bind="props"
            variant="text"
          >
            Technologies
            <v-icon right dark> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template #activator="{ props }">
          <v-btn class="menu-item" v-bind="props" variant="text">
            Services
            <v-icon right dark> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
    <v-btn elevation="0" class="btn_inquiry mr-8"> Inquiry </v-btn>
  </v-app-bar>
  <v-app-bar v-if="isSmall" height="40" elevation="0">
    <div
      class="d-flex flex-column"
      style="justify-content: center; width: 100%; text-align: center"
    >
      <span style="font-size: 18px; color: rgb(137, 2, 2); font-weight: 700">
        Singapore based Software Solutions
      </span>
      <div class="w-100 h-25"></div>
    </div>
  </v-app-bar>
  <v-app-bar
    fixed
    class="mt-n1"
    v-if="isSmall === true"
    height="50"
    :elevation="isSmall ? 2 : 0"
  >
    <v-spacer></v-spacer>
    <div class="desktop__app">
      <v-menu>
        <template #activator="{ props }">
          <v-btn class="menu-item ml-6" v-bind="props" variant="text">
            Technologies
            <v-icon right dark> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template #activator="{ props }">
          <v-btn class="menu-item" v-bind="props" variant="text">
            Services
            <v-icon right dark> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderVue',
  data() {
    return {
      drawer: false,
      screenWidth: window.innerWidth,
      items: [
        { title: 'Empty', path: '/' },
        { title: 'Empty', path: '/' },
        { title: 'Empty', path: '/' },
      ],
    };
  },
  computed: {
    isSmall() {
      return this.screenWidth < 640;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.btn_inquiry {
  border-radius: 0;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  background: #fd2763;
  color: white !important;
  text-transform: none !important;
}

.logo-img-container {
  min-height: 60px; /* set to whatever value suits your needs */
  min-width: 100px; /* set to whatever value suits your needs */
}
.logo-img {
  width: 100%;
  height: 100%;
}

.menu-item {
  text-transform: none !important;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;
  z-index: 10000;
  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
