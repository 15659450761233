<!-- eslint-disable vue/no-parsing-error -->
<template>
  <v-carousel
    cycle
    :height="!isSmall ? '100vh' : '90vh'"
    hide-delimiter-background
    :class="{ 'mt-n1': isSmall }"
    :show-arrows="isSmall ? false : true"
  >
    <v-carousel-item
      class="carousel-item"
      v-for="(slide, i) in items"
      :key="i"
      cover
    >
      <div
        class="carousel-item-title"
        :class="{
          'carousel-title-left': !isSmall,
          'carousel-title-center': isSmall,
        }"
      >
        <p v-if="i === 1">
          <span style="color: #23b14d">Beautify</span> your Design With
          <span style="color: #fd2763">Vuetify</span>
          <v-btn style="background: #17b962" class="card-btn">
            CONTACT US</v-btn
          >
        </p>
        <p v-if="i === 0 && isSmall === true">
          <div class="d-flex flex-column mb-3" style="color: #ff2828; line-height: 50px;">
            <span>Good</span>
            <span>Design</span>
          </div>
          Means<br />
          <div class="d-flex flex-column mt-3" style="color: #ffa500; line-height: 50px;">
            <span>Good</span>
            <span>Business</span>
          </div>
          <v-btn style="background: #0098f0" class="card-btn"
            >VIEW PORTFOLIO</v-btn></p>
        <p v-if="i === 0 && isSmall === false">
          <span style="color: #ff2828"
            >Good <br v-if="isSmall" />Design<br v-if="isSmall"
          /></span>
          Means<br />
          <span style="color: #ffa500">Good <br v-if="isSmall" />Business</span>
          <v-btn style="background: #0098f0" class="card-btn"
            >VIEW PORTFOLIO</v-btn
          >
        </p>
      </div>
      <img class="carousel-item-img" :src="slide.img" />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: window.innerWidth,
      items: [
        {
          img: require('@/assets/carousel-img-1.png'),
          title: 'Good Design Means Good Business',
        },
        {
          img: require('@/assets/carousel-img-2.png'),
          title: 'Beautify your Design With Vuetify',
        },
      ],
    };
  },
  computed: {
    isSmall() {
      return this.screenWidth < 640;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.carousel-item {
  position: relative;
}
.carousel-item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-item-title {
  position: absolute;
  font-weight: 700;
  font-size: 60px;
  line-height: 80px;

  z-index: 1;
  color: white;
  text-align: left;
}

.carousel-title-left {
  top: 35%;
  left: 20%;
  width: 40%;
  transform: translate(-20%, -20%);
}

.carousel-title-center {
  bottom: 20%;
  left: 55%;
  transform: translateX(-50%);
  width: 90%;
  font-size: 50px;
  font-weight: 700;
  line-height: 90px;
}

.v-carousel__delimiter {
  color: #f44336 !important;
}

.card-btn {
  border-radius: 0 !important;
  margin-top: 40px;
  color: white;
  box-shadow: none;
  font-weight: 400;
  font-size: 24px;
  height: 60px;
  display: block;
}

@media (max-width: 1279px) {
  .carousel-title-left {
    width: 50%;
  }
}
</style>
