<template>
  <div class="card-container">
    <div style="width: 100%" v-for="(card, i) in items" :key="i">
      <v-lazy :options="{ threshold: 0.5 }" min-height="400">
        <v-card class="mx-auto card-item" min-width="200" height="400">
          <v-img
            :src="card.img"
            height="100%"
            width="100%"
            class="card-image"
            cover
          >
            <template #placeholder> <div class="skeleton" /> </template
          ></v-img>

          <v-card-title class="card-title">{{ card.title }}</v-card-title>
        </v-card>
      </v-lazy>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
};
</script>

<style scoped>
.card-container {
  display: flex;
  gap: 30px;
}

.card-item {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.card-title {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media (max-width: 1279px) {
  .card-container {
    flex-wrap: wrap;
  }
}
</style>
