<template>
  <div class="footer-cont">
    <hr />
    <v-container class="footer-link-cont">
      <v-row class="d-flex justify-center">
        <v-col cols="12" xs="12" sm="6" md="3">
          <h2 class="footer_title">About The Gypsy</h2>
          <p
            class="footer_paragraph"
            style="margin-bottom: 16px; margin-top: 34px"
          >
            The gypsy is a Super App that is useful for users travelling from
            point A to point B in search of anything and everything!!!
          </p>
          <ul class="footer_social">
            <li>
              <v-icon
                color="#00cdcd"
                size="20"
                class="mr-2 mdi mdi-map-marker"
                aria-hidden="true"
              ></v-icon>
              Marine drive, Singapore
            </li>
            <li>
              <v-icon
                color="#00cdcd"
                size="20"
                class="mr-2 fa fa-phone"
                aria-hidden="true"
              ></v-icon>
              +917738282000
            </li>
            <li>
              <v-icon
                color="#00cdcd"
                size="20"
                class="mr-2 fab fa-whatsapp"
              ></v-icon>
              +917738282000
            </li>
            <li>
              <v-icon
                color="#00cdcd"
                size="20"
                class="mr-2 fa fa-envelope"
                aria-hidden="true"
              ></v-icon>
              <a href="mailto:support@the-gypsy.in">support@the-gypsy.in</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3">
          <h2 class="footer_title" style="margin-bottom: 16px">
            Our Technologies
          </h2>
          <ul class="footer_links" style="margin-top: 37px">
            <li v-for="(link, i) in quickLinks" :key="i">
              <v-icon size="15" class="mr-2 fa fa-angle-double-right"></v-icon>
              <a :href="link.url">{{ link.name }}</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <h2 class="footer_title">Our Apps</h2>
          <v-row
            class="footer_apps"
            style="margin-top: 37px; padding-right: 20px"
          >
            <v-col cols="4">
              <p style="margin-bottom: 10px">Mall-e</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-1.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="4">
              <p style="margin-bottom: 10px">Boozards</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-2.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="4">
              <p style="margin-bottom: 10px">Flea</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-3.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="4">
              <p style="margin-bottom: 10px">Mendesliga</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-4.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="4">
              <p style="margin-bottom: 10px">Cake run</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-5.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="4">
              <p style="margin-bottom: 10px">Cafe run</p>
              <div class="our-apps">
                <v-img
                  class="our-apps-img"
                  transition="fade-transition"
                  src="@/assets/footer-img-6.png"
                >
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col>
              <a
                href="javascript:void(0)"
                style="
                  color: #00cdcd;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 19px;
                  text-decoration: none;
                "
              >
                View all</a
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col class="sign-up-footer" cols="12" xs="12" sm="6" md="3">
          <h2 class="footer_title">Inquire with us</h2>
          <p
            class="footer_paragraph footer-register"
            style="margin-bottom: 16px; margin-top: 34px"
          >
            Sign up and be part of the Gypsy Community. Please Register for a
            Gypsy ID which can be used for all our Apps.<br />
            Type your email and press get started.
          </p>
          <input class="footer_input" placeholder="Type your email" />
          <v-btn class="footer-btn"> Get started </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-footer class="bg-black justify-center footer__content">
      <div class="footer_text">
        @ The gypsy International | Singapore, India 2023 - 2025
      </div>
      <div
        class="footer_icon"
        style="display: flex; justify-content: center; gap: 15px"
      >
        <v-btn variant="text" color="#00CDCD" icon="mdi-facebook" />
        <v-btn variant="text" color="#00CDCD" icon="mdi-twitter" />
        <v-btn variant="text" color="#00CDCD" icon="mdi-instagram" />
        <v-btn variant="text" color="#00CDCD" icon="mdi-youtube" />
      </div>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: 'FooterItem',
  data() {
    return {
      quickLinks: [
        {
          name: 'Our Apps',
          url: '#',
        },
        {
          name: 'Sign Up/Register',
          url: '#',
        },
        {
          name: 'Delivery partners',
          url: '#',
        },
        {
          name: 'Merchants',
          url: '#',
        },
        {
          name: 'Employers',
          url: '#',
        },
      ],
      ourApps: [
        {
          name: 'Mall-e',
          img: require('@/assets/footer-img-1.png'),
          url: '#',
        },
        {
          name: 'Boozards',
          img: require('@/assets/footer-img-2.png'),
          url: '#',
        },
        {
          name: 'Flea',
          img: require('@/assets/footer-img-3.png'),
          url: '#',
        },
        {
          name: 'Mendesliga',
          img: require('@/assets/footer-img-4.png'),
          url: '#',
        },
        {
          name: 'Cake run',
          img: require('@/assets/footer-img-5.png'),
          url: '#',
        },
        {
          name: 'Cafe run',
          img: require('@/assets/footer-img-6.png'),
          url: '#',
        },
      ],
    };
  },
};
</script>

<style scoped>
.our-apps {
  position: relative;
  overflow: hidden;
  min-height: 10px;
}
.our-apps-img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.our-apps:hover .our-apps-img {
  transform: scale(1.2);
}
.footer-cont {
  margin-top: 20px;
}

.footer-link-cont {
  margin-top: 40px;
  margin-bottom: 80px;
}
.footer_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  display: inline;
  margin-bottom: 34px;
}
.footer_title::before {
  position: absolute;
  top: 40px;
  content: '';
  width: 60px;
  height: 2px;
  background-color: #00cdcd;
}
.footer_social,
.footer_links {
  list-style: none;
}
.footer_social li,
.footer_links li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
}
.footer_social img,
.footer_links img {
  margin-right: 10px;
}
.footer_links a {
  text-decoration: none;
  color: #000;
}
.footer_paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0em;
  text-align: left;
}
.footer-register {
  font-size: 14px;
  line-height: 140%;
}
.footer_social a {
  color: #00cdcd;
  text-decoration: none;
}
.footer_apps .v-row {
  margin: 27px -6px;
}
.footer_apps .v-col {
  padding: 6px;
}
.footer_apps p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.footer_apps img {
  width: inherit;
}
.footer_apps a {
  text-decoration: none;
  color: #000;
}
.footer_input {
  border: 1px solid #00cdcd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  width: inherit;
}
.footer_input:focus {
  outline: none;
}
.footer__content {
  display: flex;
  align-items: center;
  gap: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
}

.footer-btn {
  width: inherit;
  border-radius: 0 !important;
  margin-top: 16px;
  background: #00cdcd;
  color: white;
  text-transform: none;
  box-shadow: none;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .footer_title::before {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer_apps {
    margin-top: 55px !important;
  }

  .footer-register {
    margin-top: 70px !important;
  }
}

@media (max-width: 640px) {
  .footer__content {
    flex-direction: column;
    gap: 25px;
  }

  .footer_text {
    font-weight: 300;
    font-size: 13px;
  }

  .sign-up-footer {
    margin-top: 20px;
  }
}
</style>
